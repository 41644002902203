[include*='form-input-select()'] {
  display: block;
  padding: 0;
  position: relative;
  /* Set options to normal weight */
  /* ------------------------------------  */
  /* START OF UGLY BROWSER-SPECIFIC HACKS */
  /* ----------------------------------  */
  /* FIREFOX won't let us hide the native select arrow, so we have to make it wider than needed and clip it via overflow on the parent container.
       The percentage width is a fallback since FF 4+ supports calc() so we can just add a fixed amount of extra width to push the native arrow out of view. We're applying this hack across all FF versions because all the previous hacks were too fragile and complex.
       You might want to consider not using this hack and using the native select arrow in FF. Note this makes the menus wider than the select button because they display at the specified width and aren't clipped. Targeting hack via http://browserhacks.com/#hack-758bff81c5c32351b02e10480b5ed48e */
  /* Show only the native arrow */
  color: #000000;
  display: block;
  border-radius: 0;
  box-shadow: none;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 87%; }
  [include*='form-input-select()']::before, [include*='form-input-select()']::after {
    content: '';
    display: block;
    position: absolute;
    pointer-events: none;
    z-index: 2; }
  [include*='form-input-select()'] select {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    border: 1px solid transparent;
    font-size: 16px;
    outline: none;
    /* Focus style */ }
    [include*='form-input-select()'] select:focus {
      background-color: transparent;
      outline: none; }
  [include*='form-input-select()'] option {
    font-weight: normal; }
  [include*='form-input-select()'] x:-o-prefocus, [include*='form-input-select()']::after {
    display: none; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    [include*='form-input-select()'] select::-ms-expand {
      display: none; }
    [include*='form-input-select()'] select:focus::-ms-value {
      background: transparent;
      color: #000; } }

@-moz-document url-prefix() {
  [include*='form-input-select()'] {
    overflow: hidden; }
    [include*='form-input-select()'] select {
      width: 120%;
      width: -moz-calc(100% + 3em);
      width: calc(100% + 3em);
      /* Firefox focus has odd artifacts around the text, this kills that. See https://developer.mozilla.org/en-US/docs/Web/CSS/:-moz-focusring */ }
      @supports (-moz-appearance: none) {
        [include*='form-input-select()'] select {
          width: 100%; } }
      [include*='form-input-select()'] select:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000; } }
  @supports (-moz-appearance: none) {
    [include*='form-input-select()'] {
      width: 100%; } }
  [include*='form-input-select()']::before, [include*='form-input-select()']::after {
    content: '';
    display: block;
    position: absolute;
    pointer-events: none;
    border: 1px solid transparent;
    width: 0;
    height: 0;
    right: 8px; }
  [include*='form-input-select()']::before {
    /*
		bottom: 55%;
		border-width: 0 6.5px 8px 6.5px;
		border-bottom-color: $demo-blue;
		*/ }
  [include*='form-input-select()']::after {
    border-width: 8px 6.5px 0 6.5px;
    border-top-color: #000000;
    top: 40%; }

@-moz-document url-prefix() {
  [include*='form-input-select()'] {
    border-right: 1px solid #d9d9d9; }
    [include*='form-input-select()']:hover {
      border-right: 1px solid #000000; } }
  [include*='form-input-select()']:hover select {
    border-color: #000000; }
    [include*='form-input-select()']:hover select:focus {
      outline-color: transparent; }
  [include*='form-input-select()']:hover::before {
    border-bottom-color: #000000; }
  [include*='form-input-select()']:hover::after {
    border-top-color: #000000; }
  [include*='form-input-select()'] select {
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
    border-radius: 10px;
    letter-spacing: -0.08rem;
    font-size: 1.3rem;
    color: inherit;
    padding: 7px 10px;
    line-height: normal;
    transition: border-color 0.2s ease, outline 0.2s ease; }
    [include*='form-input-select()'] select:focus {
      outline: 1px solid #000000;
      background-color: #ffffff;
      outline-offset: -1px; }
    [include*='form-input-select()'] select[disabled], [include*='form-input-select()'] select:disabled {
      opacity: 0.4;
      cursor: not-allowed; }
    [include*='form-input-select()'] select:not(:focus):invalid {
      color: #000000; }

/*
body {
  display: flex;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1em solid $demo-blue;
  
  div {
    max-width: 16em;
  }
}

// begin spec stuff up
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
// end stuff up
*/
